import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {
  baseURL
}
from "@/config";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("accessToken") || null,
    tokenIsActive: false,
    tokenExpiresIn: null,
    tokenExpiresInSec: null,
    server: {
      requestUri: baseURL || "https://api.caena.io/api",
      // requestUri: "http://127.0.0.1:8000/api",
    },
    loggedInUserData: JSON.parse(localStorage.getItem("userData")) || null,
  },
  getters: {
    isLoggedIn: function (state) {
      return state.accessToken !== null;
    },
    tokenIsActive: function () {
      return false;
    },
    userIsAdmin: function (state) {
      if (state.loggedInUserData === null) return false;
      return state.loggedInUserData.role === "admin";
    },
    loggedInUserName: function (state) {
      if (
        state.loggedInUserData.first_name !== null &&
        state.loggedInUserData.first_name !== "" &&
        state.loggedInUserData.last_name !== null &&
        state.loggedInUserData.last_name !== ""
      ) {
        return (
          state.loggedInUserData.first_name +
          " " +
          state.loggedInUserData.last_name
        );
      } else if (
        state.loggedInUserData.name !== null &&
        state.loggedInUserData.name !== ""
      ) {
        return state.loggedInUserData.name;
      } else {
        return "No name";
      }
    },
    loggedInUserInitials: function (state) {
      if (
        state.loggedInUserData.first_name !== null &&
        state.loggedInUserData.first_name !== "" &&
        state.loggedInUserData.last_name !== null &&
        state.loggedInUserData.last_name !== ""
      ) {
        return (
          state.loggedInUserData.first_name.substring(0, 1) +
          "" +
          state.loggedInUserData.last_name.substring(0, 1)
        );
      } else if (
        state.loggedInUserData.name !== null &&
        state.loggedInUserData.name !== ""
      ) {
        const userNameArray = state.loggedInUserData.name.split(" ");
        if (userNameArray.length > 0 && userNameArray.length <= 1) {
          return userNameArray[0].substring(0, 1);
        }
        return (
          userNameArray[0].substring(0, 1) +
          "" +
          userNameArray[1].substring(0, 1)
        );
      } else {
        return "N N";
      }
    },
    loggedInUserEmail: function (state) {
      return state.loggedInUserData.email;
    }
  },
  mutations: {
    setToken: function (state, token) {
      state.accessToken = token;
    },
    removeToken: function (state) {
      state.accessToken = null;
      localStorage.removeItem("accessToken");
    },
    setLoggedInUser: function (state, userData) {
      state.loggedInUserData = userData;
    },
    removeLoggedInUser: function (state) {
      state.loggedInUserData = null;
      localStorage.removeItem("userData");
    },
  },
  actions: {
    retrieveToken({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.server.requestUri}/auth/login`, {
            email: payload.email,
            password: payload.password,
          })
          .then((response) => {
            console.log(response);
            if(response.data.success){
              const responseData = response.data.data;
              const accessToken = responseData.token;
              const userRole = responseData.user.role;
              

              if (userRole !== "admin") {
                reject("You do not have admin access!");
                return;
              }
              const loggedInUserData = {
                id: responseData.user.id,
                name: responseData.user.name,
                first_name: responseData.user.first_name,
                last_name: responseData.user.last_name,
                role: responseData.user.role,
                role_id: responseData.user.role_id,
                email: responseData.user.email,
                avatar: responseData.avatar,
              };
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("userData", JSON.stringify(loggedInUserData));
              commit("setToken", accessToken);
              commit("setLoggedInUser", loggedInUserData);
              resolve(response.data.data);
            }
            else{
              reject(response.data.message)
              return
            }
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    getUserCompany({ state }, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.server.requestUri}/admin/show-user-company/${userId}`)
          .then((res) => {
            resolve(res.data.data[0]);
          })
          .catch((err) => reject(err.response));
      });
    },
  },
});

export default store;
