import axios from "axios";
import store from "./store/store";

import {
    baseURL
  }
  from "@/config";

  const service = axios.create({
    baseURL,
  });

axios.defaults.headers.common["Authorization"] = store.state.accessToken;

service.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    }
  );

service.interceptors.response.use(
    (res) => {
      if (res.data.data && res.data.data.token) {
        const {
          token
        } = res.data.data;
        localStorage.setItem("accessToken", token);
      }
      return res;
    }, (error) => {
      if (!error.response || !error.response.status) {
        return
      }
      const status = error.response.status;
      if (status === 401) {
        localStorage.removeItem("accessToken");
        window.location.href = "/auth/login";
      }
      return Promise.reject(error)
    }
  );
export default service;

