<template>
  <div id="app">
    <router-view ></router-view>
    <!-- <router-view v-if="pageIsLoading === false"></router-view>
    <div v-else>
      <div id="loading-bg">
        <div class="loading-logo">
          <img src="./assets/images/logo/logo.svg" alt="Logo" />
        </div>
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
@import "./assets/css/style.css";
</style>
