import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/auth",
      component: () => import("./views/layouts/AuthLayout"),
      redirect: "admin-login",
      children: [
        {
          path: "login",
          name: "admin-login",
          component: () => import("./views/pages/auth/Login"),
        },
      ],
      meta: {
        visitorRequired: true,
      },
    },
    {
      path: "/",
      component: () => import("./views/layouts/Main"),
      children: [
        {
          path: "",
          name: "dashboard",
          component: () => import("./views/pages/Dashboard/Dashboard"),
        },
        /*
          ------------------USERS MANAGEMENT ---------------
          --------------------------------------------------
         */
        {
          path: "users",
          name: "users",
          component: () => import("./views/pages/users/Users"),
        },
        {
          path: "users/create",
          name: "create-user",
          component: () => import("./views/pages/users/CreateUser"),
        },
        {
          path: "users/:id",
          name: "view-user",
          redirect: "vu-edit-user",
          component: () => import("./views/pages/users/ViewUser"),
          children: [
            {
              path: "edit",
              name: "vu-edit-user",
              component: () => import("./views/pages/users/sub-pages/EditUser"),
            },
            {
              path: "payment-history",
              name: "vu-payment-history",
              component: () =>
                import("./views/pages/users/sub-pages/PaymentHistory"),
            },
            {
              path: "valuations",
              name: "vu-valuations",
              component: () =>
                import("./views/pages/users/sub-pages/CompanyInfo"),
            },
          ],
        },
        /*
         ------------------COMPANY INFO ---------------
         --------------------------------------------------
        */
        {
          path: "company-info",
          name: "company-info",
          component: () => import("./views/pages/company-info/CompanyInfo"),
        },
        {
          path: "company-info/:id/view",
          name: "view-company-info",
          component: () => import("./views/pages/company-info/ViewCompanyInfo"),
        },
        {
          path: "company-info/:id/edit",
          name: "edit-company-info",
          component: () => import("./views/pages/company-info/EditCompanyInfo"),
        },
        {
          path: "company-info/create-new",
          name: "add-new-company",
          component: () =>
            import("./views/pages/company-info/CreateNewCompanyInfo"),
        },
        {
          path: "company-info/:id/company-models",
          name: "view-company-models",
          component: () => import("./views/pages/company-info/CompanyModels"),
        },
        /*
          ------------------INVESTORS ---------------

          --------------------------------------------------
        */
          {
            path: "investors",
            name: "investors",
            component: () => import("./views/pages/investors/Investors"),
          },
          {
            path: "investors/create",
            name: "create-investor",
            component: () => import("./views/pages/investors/CreateInvestor"),
          },
          {
            path: "investors/:id",
            name: "view-investor",
            redirect: "vu-edit-investor",
            component: () => import("./views/pages/investors/ViewInvestor"),
            children: [
              {
                path: "edit",
                name: "vu-edit-investor",
                component: () => import("./views/pages/investors/sub-pages/EditInvestor"),
              },
            ],
          },
        /*
         ------------------SUBSCRIPTIONS ---------------
         --------------------------------------------------
        */
        {
          path: "subscriptions",
          name: "subscriptions",
          redirect: "/subscriptions/free",
          component: () => import("./views/pages/subscriptions/Subscriptions"),
          children: [
            {
              path: "free",
              name: "free-subscription-plan",
              component: () =>
                import(
                  "./views/pages/subscriptions/sub-pages/FreeSubscriptions"
                ),
            },
            {
              path: "paid",
              name: "paid-subscription-plan",
              component: () =>
                import(
                  "./views/pages/subscriptions/sub-pages/PaidSubscriptions"
                ),
            },
            {
              path: "cancelled",
              name: "cancelled-subscription-plan",
              component: () =>
                import(
                  "./views/pages/subscriptions/sub-pages/CancelledSubscriptions"
                ),
            },
          ],
        },
        /*
         ------------------PAYMENT HISTORY ---------------
         --------------------------------------------------
        */
        {
          path: "payment-history",
          name: "payment-history",
          component: () => import("./views/pages/PaymentHistory"),
        },
        /*
         ------------------COUPON MANAGEMENT ---------------
         --------------------------------------------------
        */
        {
          path: "coupons",
          name: "coupons",
          component: () => import("./views/pages/coupons/Coupons"),
        },
        {
          path: "coupons/:couponId",
          name: "coupon",
          redirect: "promo-codes",
          component: () => import("./views/pages/coupons/coupon/ManageCoupon"),
          children: [
            {
              path: "/",
              name: "edit-coupon",
              component: () =>
                import("./views/pages/coupons/coupon/sub-pages/EditCoupon"),
            },
            {
              path: "promo-codes",
              name: "promo-codes",
              component: () =>
                import("./views/pages/coupons/coupon/sub-pages/PromoCodes"),
            },
            {
              path: "promo-codes/create",
              name: "create-promo-code",
              component: () =>
                import(
                  "./views/pages/coupons/coupon/sub-pages/CreatePromoCode"
                ),
            },
            {
              path: "promo-codes/:id/edit",
              name: "edit-promo-code",
              component: () =>
                import("./views/pages/coupons/coupon/sub-pages/EditCoupon"),
            },
          ],
        },
        {
          path: "coupons/manage-coupons",
          name: "manage-coupons",
          component: () => import("./views/pages/coupons/ManageCoupons"),
          children: [
            {
              path: "create-coupon",
              name: "create-coupon",
              component: () =>
                import("./views/pages/coupons/sub-pages/CreateCoupon"),
            },
            {
              path: "active-coupons",
              name: "active-coupons",
              component: () =>
                import("./views/pages/coupons/sub-pages/ActiveCoupons"),
            },
            {
              path: "coupon-history",
              name: "coupon-history",
              component: () =>
                import("./views/pages/coupons/sub-pages/CouponHistory"),
            },
          ],
        },
        /*
         ------------------PACKAGES ---------------
         --------------------------------------------------
        */
        {
          path: "packages",
          name: "packages",
          component: () => import("./views/pages/packages/Packages"),
        },
        {
          path: "packages/create-new",
          name: "create-package",
          component: () => import("./views/pages/packages/CreatePackage"),
        },
        {
          path: "packages/:id/edit",
          name: "edit-package",
          component: () => import("./views/pages/packages/EditPackage"),
        },

        /*
         ------------------ADMIN ---------------
         --------------------------------------------------
        */
        {
          path: "admins",
          name: "admins",
          component: () => import("./views/pages/Admin/Admins"),
        },
        {
          path: "admins/roles",
          name: "admins-roles",
          component: () => import("./views/pages/Admin/Roles"),
        },
        {
          path: "admin/new",
          name: "admin-new",
          component: () => import("./views/pages/Admin/sub-pages/NewAdmin"),
        },
        {
          path: "admin/edit/:id",
          name: "admin-edit",
          component: () => import("./views/pages/Admin/sub-pages/EditAdmin"),
        },
        {
          path: "admin/add/role",
          name: "add-role",
          component: () => import("./views/pages/Admin/sub-pages/AddRoles"),
        },
        {
          path: "admin/role/edit/:id",
          name: "edit-role",
          component: () => import("./views/pages/Admin/sub-pages/EditRoles"),
        },
      ],
      meta: {
        authRequired: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    // Always log user out if user is not logged in
    // or the currently logged in user is not an admin
    if (!store.getters.isLoggedIn) {
      return router.push({ name: "admin-login" });
    }
    if (!store.getters.userIsAdmin) {
      store.commit("removeLoggedInUser");
      return router.push({ name: "admin-login" });
    }
  }
  next();
});

//Admin should not be able to access auth page when logged in
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.visitorRequired)) {
    if (store.getters.isLoggedIn) {
      return router.push({ name: "dashboard" });
    }
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
