import Vue from "vue";
import App from "./App.vue";
import Vuesax from "vuesax";
import VeeValidate from "vee-validate";
import "vuesax/dist/vuesax.css";
import axios from './axios'



Vue.use(Vuesax, {});

Vue.config.productionTip = false;

import router from "./router";
import store from "./store/store";
import Toast from "vue-toastification";
//import "./mixins";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const toastOptions = {};
Vue.use(Toast, toastOptions);
Vue.use(VeeValidate);

Vue.prototype.$_LIST_STARTUP_STAGE                       = {};
Vue.prototype.$_LIST_STARTUP_STAGE['idea']               = 'Idea';
Vue.prototype.$_LIST_STARTUP_STAGE['mvp']                = 'MVP';
Vue.prototype.$_LIST_STARTUP_STAGE['product_launched']   = 'Product Launched';
Vue.prototype.$_LIST_STARTUP_STAGE['revenue_generating'] = 'Revenue Generating';
Vue.prototype.$_LIST_STARTUP_STAGE['scaling']            = 'Scaling';

Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION                          = {};
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['online']                = 'Online';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['retail_locations']      = 'Retail locations';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['inside_sales']          = 'Inside Sales';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['partnerships']          = 'Partnerships';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['direct_sales']          = 'Direct sales';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['value_added_resellers'] = 'Value-added Resellers';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['franchise']             = 'Franchise';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['licensing']             = 'Licensing';
Vue.prototype.$_LIST_SALES_AND_DISTRIBUTION['others']                = 'Others';

Vue.prototype.$_LIST_INVESTMEMT_STAGE             = {};
Vue.prototype.$_LIST_INVESTMEMT_STAGE['pre_seed'] = 'Pre-Seed';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['seed']     = 'Seed';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_a'] = 'Series A';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_b'] = 'Series B';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_c'] = 'Series C';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_d'] = 'Series D';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_e'] = 'Series E';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_f'] = 'Series F';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_g'] = 'Series G';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_h'] = 'Series H';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_i'] = 'Series I';
Vue.prototype.$_LIST_INVESTMEMT_STAGE['series_j'] = 'Series J';

Vue.prototype.$_LIST_INVESTOR_TYPE                              = {};
Vue.prototype.$_LIST_INVESTOR_TYPE['accelerator']               = 'Accelerator';
Vue.prototype.$_LIST_INVESTOR_TYPE['angel_group']               = 'Angel Group';
Vue.prototype.$_LIST_INVESTOR_TYPE['corporate_venture_capital'] = 'Corporate Venture Capital';
Vue.prototype.$_LIST_INVESTOR_TYPE['co_working_space']          = 'Co-Working Space';
Vue.prototype.$_LIST_INVESTOR_TYPE['entrepreneurship_program']  = 'Entrepreneurship Program';
Vue.prototype.$_LIST_INVESTOR_TYPE['family_investment_office']  = 'Family Investment Office';
Vue.prototype.$_LIST_INVESTOR_TYPE['fund_of_funds']             = 'Fund Of Funds';
Vue.prototype.$_LIST_INVESTOR_TYPE['government_office']         = 'Government Office';
Vue.prototype.$_LIST_INVESTOR_TYPE['hedge_fund']                = 'Hedge Fund';
Vue.prototype.$_LIST_INVESTOR_TYPE['incubator']                 = 'Incubator';
Vue.prototype.$_LIST_INVESTOR_TYPE['individual']                = 'Individual/Angel';
Vue.prototype.$_LIST_INVESTOR_TYPE['investment_bank']           = 'Investment Bank';
Vue.prototype.$_LIST_INVESTOR_TYPE['investment_partner']        = 'Investment Partner';
Vue.prototype.$_LIST_INVESTOR_TYPE['micro_vc']                  = 'Micro VC';
Vue.prototype.$_LIST_INVESTOR_TYPE['pension_funds']             = 'Pension Funds';
Vue.prototype.$_LIST_INVESTOR_TYPE['private_equity_firm']       = 'Private Equity Firm';
Vue.prototype.$_LIST_INVESTOR_TYPE['secondary_purchaser']       = 'Secondary Purchaser';
Vue.prototype.$_LIST_INVESTOR_TYPE['startup_competition']       = 'Startup Competition';
Vue.prototype.$_LIST_INVESTOR_TYPE['syndicate']                 = 'Syndicate';
Vue.prototype.$_LIST_INVESTOR_TYPE['university_program']        = 'University Program';
Vue.prototype.$_LIST_INVESTOR_TYPE['venture_capital']           = 'Venture Capital';
Vue.prototype.$_LIST_INVESTOR_TYPE['venture_debt']              = 'Venture Debt';

Vue.prototype.$_LIST_INVESTMENT_TYPE                          = {};
Vue.prototype.$_LIST_INVESTMENT_TYPE['convertible_note']      = 'Convertible Note';
Vue.prototype.$_LIST_INVESTMENT_TYPE['corporate_round']       = 'Corporate Round';
Vue.prototype.$_LIST_INVESTMENT_TYPE['debt_financing']        = 'Debt Financing';
Vue.prototype.$_LIST_INVESTMENT_TYPE['equity_crowdfunding']   = 'Equity Crowdfunding';
Vue.prototype.$_LIST_INVESTMENT_TYPE['funding_round']         = 'Funding Round';
Vue.prototype.$_LIST_INVESTMENT_TYPE['grant']                 = 'Grant';
Vue.prototype.$_LIST_INVESTMENT_TYPE['initial_coin_offering'] = 'Initial Coin Offering';
Vue.prototype.$_LIST_INVESTMENT_TYPE['non_equity_assistance'] = 'Non-equity Assistance';
Vue.prototype.$_LIST_INVESTMENT_TYPE['post_ipo_debt']         = 'Post-IPO Debt';
Vue.prototype.$_LIST_INVESTMENT_TYPE['post_ipo_equity']       = 'Post-IPO Equity';
Vue.prototype.$_LIST_INVESTMENT_TYPE['post_ipo_secondary']    = 'Post-IPO Secondary';
Vue.prototype.$_LIST_INVESTMENT_TYPE['private_equity']        = 'Private Equity';
Vue.prototype.$_LIST_INVESTMENT_TYPE['product_crowdfunding']  = 'Product Crowdfunding';
Vue.prototype.$_LIST_INVESTMENT_TYPE['secondary_market']      = 'Secondary Market';

Vue.prototype.$_LIST_SHAREHOLDERS                       = {};
Vue.prototype.$_LIST_SHAREHOLDERS['family_and_friends'] = 'Family and Friends';
Vue.prototype.$_LIST_SHAREHOLDERS['crowdfunding']       = 'Crowdfunding';
Vue.prototype.$_LIST_SHAREHOLDERS['university']         = 'University';
Vue.prototype.$_LIST_SHAREHOLDERS['incubator']          = 'Incubator/Accelerator';
Vue.prototype.$_LIST_SHAREHOLDERS['business_angel']     = 'Business Angel';
Vue.prototype.$_LIST_SHAREHOLDERS['venture_capitalist'] = 'Venture Capitalist';

Vue.prototype.$_LIST_COMPANY_AAOTF      =
[
  { text: "< 25",    value: "25" },
  { text: "25 - 34", value: "34" },
  { text: "35 - 44", value: "44" },
  { text: "> 45",    value: "45" }
];
Vue.prototype.$_LIST_COMPANY_FE        =
[
  { text: "First Entrepreneurial Experience",       value: "1" },
  { text: "Founded At Least One Company Before",    value: "2" },
  { text: "Successfully Sold At Least One Company", value: "3" }
];
Vue.prototype.$_LIST_COMPANY_HLHCMWT   =
[
  { text: "< 1 year",    value: "1" },
  { text: "1 - 2 years", value: "2" },
  { text: "3 - 4 years", value: "4" },
  { text: "> 5 years",   value: "5" }
];
Vue.prototype.$_LIST_COMPANY_TCMBM     =
[
  { text: "B2C (Business To Customers)", value: "1" },
  { text: "B2B (Business To Business)",  value: "2" }
];
Vue.prototype.$_LIST_COMPANY_AB        =
[
  { text: "Not Stablished Yet", value: "1" },
  { text: "There Is A Formal Advisory Board",  value: "2" }
];
Vue.prototype.$_LIST_COMPANY_BFME      =
[
  { text: "Disruptive (Very Low Barriers)",             value: "1" },
  { text: "Perfect Competition (Low Barriers)",         value: "2" },
  { text: "Monopolistic Competition (Medium Barriers)", value: "3" },
  { text: "Oligopoly (High Barriers)",                  value: "4" },
  { text: "Monopoly (Very High Barriers)",              value: "5" },
];
Vue.prototype.$_LIST_COMPANY_TYPE      =
[
  { text: "Agriculture",                value: "agriculture" },
  { text: "Artificial Intelligence",    value: "artificialintelligence" },
  { text: "Biopharma / Biotechnology",  value: "biopharmabiotechnology" },
  { text: "Biotech / Healthtech",       value: "biotechhealthtech" },
  { text: "Cybersecurity",              value: "cybersecurity" },
  { text: "E-Commerce",                 value: "ecommerce" },
  { text: "Energy",                     value: "energy" },
  { text: "Entertainment",              value: "entertainment" },
  { text: "Fashion",                    value: "fashion" },
  { text: "Fashiontech",                value: "fashiontech" },
  { text: "Financial Services",         value: "financialservices" },
  { text: "Fintech",                    value: "fintech" },
  { text: "Fitness",                    value: "fitness" },
  { text: "Food and Beverage",          value: "foodandbeverage" },
  { text: "Foodtech",                   value: "foodtech" },
  { text: "Gaming",                     value: "gaming" },
  { text: "Healthcare",                 value: "healthcare" },
  { text: "Legal Services",             value: "legalservices" },
  { text: "Marketplace",                value: "marketplace" },
  { text: "Media",                      value: "media" },
  { text: "Proptech",                   value: "proptech" },
  { text: "Real Estate",                value: "realestate" },
  { text: "Robotics",                   value: "robotics" },
  { text: "Software Development",       value: "softwaredevelopment" },
  { text: "Others",                     value: "others" }
];
Vue.prototype.$_LIST_SOCIAL_LINKS       =
[
  {name: 'Facebook',  code: 'facebook'  },
  {name: 'Instagram', code: 'instagram' },
  {name: 'LinkedIn',  code: 'linkedin'  },
  {name: 'Other',     code: 'other'     }
];
Vue.prototype.$_LIST_COUNTRIES          =
[
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Åland Islands', code: 'AX'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Brunei Darussalam', code: 'BN'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'China', code: 'CN'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo', code: 'CG'},
  {name: 'Congo, The Democratic Republic of the', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands (Malvinas)', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran, Islamic Republic Of', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
  {name: 'Korea, Republic of', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Lao People\'S Democratic Republic', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan Arab Jamahiriya', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Malaysia', code: 'MY'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia, Federated States of', code: 'FM'},
  {name: 'Moldova, Republic of', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian Territory, Occupied', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia and Montenegro', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Taiwan, Province of China', code: 'TW'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania, United Republic of', code: 'TZ'},
  {name: 'Thailand', code: 'TH'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'Viet Nam', code: 'VN'},
  {name: 'Virgin Islands, British', code: 'VG'},
  {name: 'Virgin Islands, U.S.', code: 'VI'},
  {name: 'Wallis and Futuna', code: 'WF'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
]


export const EventBus = new Vue();
new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");



